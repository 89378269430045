<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit")
			.card.card-custom.gutter-b
				.card-header
					.card-title
						.card-label Sources Blacklist
				.card-body
					.form-group
						textarea.form-control(rows="10", v-model="sources")
					.form-group
						button.btn.btn-primary(type="submit") Submit

</template>
<script>
import {SET_BREADCRUMB} from "../../../../core/services/store/breadcrumbs.module";

export default {
	name: 'SourceBlacklist',
	data(){
		return {
			busy: false,
			sources: ''
		};
	},
	methods: {
		async submit(){
			if (this.busy){
				return;
			}
			this.busy = true;
			try {
				let data = {
					sources: this.sources
				};
				let resp = await this.$api.post('data-control/blacklistSources', data);
				this.$notify.success('Sources have been blacklisted');
			} catch (e){

			}
			this.busy = false;
		}
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Source Blacklist', name: 'source-blacklist'},
		]);
	}
}
</script>
